<template>
  <div class="main">
    <van-form @submit="onSubmit" style="margin-top:10px">
      <van-cell-group inset>
        <van-field v-model="query.start_date" readonly label="预计开始时间" />
        <van-field v-model="query.end_date" readonly label="预计结束时间" />
        <van-field
          v-model="query.delay_date"
          is-link
          name="datetimePicker"
          :rules="[{ required: true, message: '请选择延期时间' }]"
          label="延期至"
          placeholder="点击选择时间"
          @click="showPicker = true"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

    <!-- 延期时间 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :min-date="new Date(query.end_date)"
      />
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import {reactive, ref} from 'vue';
import { Notify ,Dialog} from 'vant';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const showPicker = ref(false)
        const query = reactive({
            action:'delay',
            method:'saveorupdate',
            t:'PTW_CETIFICATE',
            id:route.params.id,
            start_date:route.params.start,
            end_date:route.params.end,
            delay_date:''
        });
        const onConfirm = (data) =>{
            query.delay_date = Http.momentDate(data,'YYYY-MM-DD HH:mm:ss');
            showPicker.value = false;
        }

        const onSubmit = () =>{
            Dialog.confirm({
                title: '标题',
                message: '是否确认提前延期申请 ？',
            })
            .then(() => {
                Http.post(query,false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id);
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }

        return {
            query,
            showPicker,
            onConfirm,
            onSubmit
        }
    },
}
</script>